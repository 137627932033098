<template>
        <div class="student-comment-detail">
            <div class="journal-nav" v-if="!loading">
                <div>
                    <img lazy-load :src="student.student_avatar">
                </div>
                <div>{{student.student_name}}</div>
                <div v-if="student.student_number">({{student.student_number}})</div>
                <!-- <div class="journal-nav-time">{{student.student_number}}</div> -->
            </div>
            <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="listFinishedText"
            @load="onLoad"
            style="margin-bottom:20px"
            >
            <div v-for="(item,index) in list" :key="index" class="schedule-comment-list">
                <div class="schedule-comment-list-nav">
                    <avatar :src="item.creater.teacher_avartar" color="#fff" :size="28"></avatar>
                    <div class="schedule-comment-list-name">
                        <div>{{item.creater.teacher_name}}</div>
                        <div class="schedule-comment-list-time">{{item.message_created_at}}</div>
                    </div>
                </div>
                <div class="schedule-comment-list-content">
                    {{item.message_content}}
                </div>
                <div class="schedule-comment-list-img">
                    <van-image
                    v-for="(it,j) in item.message_images"
                    :key="j"
                    @click="toPreview(item.message_images,j)"
                    width="65"
                    height="65"
                    fit="contain"
                    :src="it"
                    />
                </div>

                <div class="schedule-comment-list-like">
                    <van-icon name="good-job-o" :color="item.message_is_liked?'#00cca2':''" />
                    <span :style="{marginLeft:'10px',color:item.message_is_liked?'#00cca2':''}">{{item.like_count}}</span>
                </div>

                <van-cell title="上课内容" v-if="item.book_id" size="small" title-style="color: #999;" is-link @click="getSummary(item)" />

            </div>
            <van-empty v-if="list.length == 0 && !loading" description="暂无相关数据~" />
            </van-list>
            <!-- <van-sidebar class="comment-sidebar" v-model="active">
                <van-sidebar-item @click="handleChange(index)" v-for="(item,index) in course_class_books" :key="index">
                    <template slot="title">
                        <div class="comment-sidebar-ellipsis">
                            <van-icon name="star-o" :color="item.message_content?'#00cca2':''" />
                            <div class="van-ellipsis  comment-sidebar-name">{{item.student_name}}</div>
                        </div>
                    </template>
                </van-sidebar-item>
            </van-sidebar>
            <div v-if="course_class_books.length>0" style="flex:1" class="comment-item-box" ref="comment_item_box" @scroll="handleScroll">
                <div v-for="(item,index) in course_class_books" :key="index" :ref="item.book_id" class="comment-item">
                    <div class="comment-item-user">
                        <img :src="item.student_avatar">
                        <span>{{item.student_name}}</span>
                    </div>
                    <div class="comment-item-textarea van-hairline--surround">
                        <van-field
                            v-model="item.message_content"
                            rows="4"
                            autosize
                            label=""
                            type="textarea"
                            maxlength="500"
                            placeholder="请输入评价"
                            show-word-limit
                        />
                    </div>
                </div>
                <div v-if="course_class_books.length>1" style="height:93vh"></div>
            </div> -->
            <van-button @click="toAdd" type="primary" class="report-btn" block>提交课后反馈</van-button>
            <loadings :loading='loading' />


        <van-popup v-model="showSummary" round position="bottom" :style="{ height: '85%' }" >
            <div class="schedule-detail-html" v-if="html">
                <div @click="toRichPreview($event)" class="ql-editor" v-html="html"></div>
            </div>
            <div v-else>
                <van-empty description="暂无上课内容~" />
            </div>
        </van-popup>    
        
        <van-popup v-model="show" position="bottom" :style="{ height: '100%' }" >
            <div class="schedule-comment-popup">
                <div class="schedule-comment-popup-nav">
                    <div @click="cancel">
                        <van-button size="small" type="default">取消</van-button>
                    </div>
                    <div>
                        <van-button :loading="isConfirm" @click="confirm" size="small" type="primary">确认</van-button>
                    </div>
                </div>
                <div class="schedule-comment-popup-form">

                    <van-form @submit="confirm" :colon='true'>
                        <van-field
                            v-model="message"
                            rows="2"
                            autosize
                            label="文字评论"
                            name="content"
                            type="textarea"
                            maxlength="1000"
                            placeholder="请输入评论内容"
                            show-word-limit
                            class="survey-van-textarea-boeder"
                            :required='true'
                            :rules="[{ required: true, message: '请输入' }]"
                        />
                        <van-field 
                            name="image"
                            label="图片评论"
                            :required='false'
                            >
                            <template #input>
                                <van-uploader :after-read="e=>afterRead(e)" :before-delete='(e,b)=>beforeDelete(e,b)' multiple :max-count="9" v-model="imgList" />
                            </template>
                        </van-field>
                    </van-form>
                </div>
            </div>
        </van-popup>
        </div>
</template>

<script>
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import Avatar from 'vue-avatar'
    import upload from '@/utils/upload'
    import Loadings from '@/components/loading'
    import {addRandomAndGetPreviewImageUrlsFromRichText} from '@/utils/imgPreview'
    import { Sidebar, SidebarItem, Field, Uploader, Form, Empty } from 'vant'
    export default {
        name:'student-comment',
        data() {
            return {
                active: 0,
                message:'',
                show:false,
                isConfirm:false,
                imgList:[],
                student:{},
                showSummary:false,
                finished: false,
                loading:false,
                searchLoading:true,
                pageSize: 10,
                pageCurrent: 1,
                listFinishedText: '没有更多了',
                course_class_id:'',
                student_id:'',
                book_id:'',
                list:[],
                html:'',
                richImages:[],
                course_class_books:[],
            }
        },
        components: {
            [Form.name]:Form,
            [Field.name]:Field,
            [Sidebar.name]:Sidebar,
            [Uploader.name]:Uploader,
            [SidebarItem.name]:SidebarItem,
            loadings:Loadings,
            [Empty.name]:Empty,
            [Avatar.name]: Avatar,
        },
        created () {
            this.student_id = this.$route.query.student_id
            this.book_id = this.$route.query.book_id
            this.getDetail()
            this.getList()
        },
        methods: {
            getSummary(item){
                this.showSummary=true;
                this.$api.schedule_summary({book_id:item.book_id}).then(res => {
                    if(res.data.class_summary){
                        let arr = addRandomAndGetPreviewImageUrlsFromRichText(res.data.class_summary)
                        this.html = arr[0]
                        this.richImages = arr[1]
                    }
                })
            },
            onLoad() {
                this.getList()
            },
            async getDetail(){
                await this.$api.employee_student({student_id:this.student_id})
                .then(res=>{
                    this.student=res.data
                })
            },
            getList() {
                this.loading = true
                this.$api.employee_schedule_comment({student_id:this.student_id,'per-page': this.pageSize, page: this.pageCurrent})
                .then(res=>{
                    this.loading = false
                    this.searchLoading = false
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    console.log(this.list.length >= res._meta.totalCount)
                    this.finished=true;
                    this.finished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了~' : ''
                    this.pageCurrent += 1
                })
            },
            toRichPreview(e){
                if (e.target.tagName == 'IMG') {
                    WeixinJSBridge.invoke("imagePreview", {
                        "urls":this.richImages,
                        "current": this.richImages[e.target.src.split('random=')[1] - 1]
                    });
                }
            },
            afterRead(file,name) {
                this.$forceUpdate()
                let that = this
                this.isConfirm = true
                if(!file.length){
                    upload({ file: file.file })
                    .then(res => {
                        file.path  = res.fileName
                    })
                    .catch(err => {
                        that.$toast({ message: '图片上传失败~', duration: 1500 })
                    })
                }else{
                    file.forEach(it=>{
                        that.isConfirm = true
                        upload({ file: it.file })
                            .then(res => {
                                it.path  = res.fileName
                            })
                            .catch(err => {
                                that.$toast({ message: '图片上传失败~', duration: 1500 })
                            })
                    })
                }
                setTimeout(()=>{
                    that.isConfirm = false
                },1500)
            },
            beforeDelete(file,detail,name){
                this.imgList.splice(detail.index,1)
                this.$forceUpdate()
                return true
            },
            cancel(){
                this.message = ''
                this.show = false
            },
            confirm(){
                if(!this.message){
                    this.$toast('请输入评论内容～')
                    return false
                }
                let obj = {
                    message_content:this.message,
                    student_id:this.student_id,
                    book_id:this.book_id,
                    message_images:[]
                }
                if(this.imgList.length>0){
                    obj.message_images = this.imgList.map((item)=>{return item.path})
                }
                this.loading = true
                this.isConfirm = true
                this.$api.qywx_external_add_message(obj)
                .then(res=>{
                    this.finished = false
                    this.list = []
                    this.imgList = []
                    this.pageCurrent = 1
                    this.$toast('操作成功～')
                    this.getList()
                    this.message = ''
                    this.show = false
                })
            },
            toPreview(item,index){
                WeixinJSBridge.invoke("imagePreview", {
                    "urls":item,
                    "current": item[index]
                })
            },
            toAdd(){
                this.show=true;
                // let obj = {
                //     course_class_id: this.course_class_id,
                //     schedule_comment: this.course_class_books
                // }
                // this.$api.employee_add_schedule_comment(obj)
                // .then(res=>{
                //     this.$toast('提交成功～')
                //     this.getList()
                // })
            },
            handleScroll(e){
                let num = parseInt(e.target.scrollTop/231)
                if(num >= this.course_class_books.length-1){
                    this.active = this.course_class_books.length-1
                }else{
                    this.active = num
                }
            },
            handleChange(e){
                this.toAnchor(this.course_class_books[e].book_id)
            },
            toAnchor(book_id){
                this.$refs.comment_item_box.scrollTop = this.$refs[book_id][0].offsetTop
            }
        },
    }
</script>

<style lang="less" scoped>
    // .student-comment-detail{
    //     display:flex;
    //     height: 93vh;
    //     overflow: hidden;
    // }
    // .comment-sidebar{
    //     height: 93vh;
    //     overflow-y: auto;
    //     width: 120px;
    //     &-ellipsis{
    //         display: flex;
    //         align-items: center;
    //     }
    //     &-name{
    //         width: 95px;
    //         margin-left:5px;
    //     }
    // }
    // .comment-item{
    //     padding: 15px 10px;
    //     &-box{
    //         height: 93vh;
    //         overflow-y: auto;
    //     }
    //     &-user{
    //         display: flex;
    //         align-items: center;
    //         margin-bottom: 10px;
    //         img{
    //             width: 36px;
    //             height: 36px;
    //             border-radius: 50%;
    //             margin-right: 10px;
    //         }
    //     }
    // }
</style>
<style lang="less" scoped>
    .journal-nav{
        background: #fff;
        padding: 0 15px;
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #eee;
        img{
            margin-left: 15px;
            width: 28px;
            height: 28px;
            border-radius: 2px;
            margin-right: 5px;
        }
        .journal-nav-time{
            position: absolute;
            right: 0;
        }
    }
    .report-btn{
        width: 100%;
        height: 40px;
        background-color: #00DE93;
        position: fixed;
        bottom: 0;
        text-align: center;
        line-height: 50px;
        color: #fff;
    }
    .schedule-comment-list{
        width: 90%;
        background: #fff;
        margin: 0 auto;
        margin-top: 15px;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0 0 2px 3px hsla(0,0%,86.3%,.1);
        position: relative;
        &-nav{
            display: flex;
            align-items: center;
        }
        &-name{
            margin-left:15px;
        }
        &-time{
            font-size: 12px;
            color: #999;
        }
        &-content{
            margin-top:10px;
            word-break: break-all;
        }
        &-img{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .van-image{
                margin: 10px;
            }
        }
        &-like{
            position: absolute;
            right: 10px;
            top: 10px;
            display: flex;
            align-items: center;
        }
    }
    .schedule-comment-popup{
        &-nav{
            display: flex;
            justify-content: space-between;
            padding: 10px;
        }
    }
    .float-ball{
        position: fixed;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 2px #999;
        .van-icon{
            font-size: 0.8rem;
        }
    }
</style>

<style lang="less">
    .schedule-comment-popup-form{
        .van-cell{
            display: block;
        }
        .van-field__label{
            width: 100%;
        }
        .survey-van-field-boeder{
            .van-field__body{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
        .survey-van-textarea-boeder{
            .van-cell__value{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
    }
</style>